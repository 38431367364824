import * as React from "react";
import { LinksProps } from "./typings";
import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSLink } from "@egds/react-core/link";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { DefaultFlexModuleResult, Link } from "typings/microserviceModels/links-flex-module";
import { LocalizedText } from "bernie-l10n";
import { GeoType } from "src/typings/flexFramework/FlexDefinitions";
import { LocationType } from "src/components/utility/LocationUtils";
import { InsideTabGroupContext } from "components/shared/InsideTabGroupContext/InsideTabGroupContext";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLayoutGrid, EGDSLayoutGridColumnRow, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSSpacing, EGDSSpacingProps, EGDSSpacingSpaces } from "@egds/react-core/spacing";
import { observer } from "mobx-react";
import { withStores } from "src/stores";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { useLocalization } from "@shared-ui/localization-context";
import {
  ToggleAllContainerContext,
  ToggleContainerState,
} from "src/components/flexComponents/ToggleContainer/views/ToggleAllContainer";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { InView } from "react-intersection-observer";
import { useEgClickstream } from "@shared-ui/clickstream-analytics-context";
import { ALT_ACC_STRATEGY } from "./utils/constants";
import {
  egClickstreamAlternateAccommodationsPresentedEvent,
  egClickstreamLinksPresentedEvent,
} from "./utils/egLinksEvents";

const getEGDSLink = (model: DefaultFlexModuleResult, el: Link, rfrrid: string) => {
  const text = `${el.text} ${(el.auxiliaryText && `(${el.auxiliaryText})`) || ""}`;

  if (!el.url) {
    return (
      <EGDSText inline size={300}>
        {text}
      </EGDSText>
    );
  }

  return (
    <EGDSLink>
      <TrackedLink moduleName={model.name} href={el.url} rfrr={rfrrid} linkName={el.text}>
        {text}
      </TrackedLink>
    </EGDSLink>
  );
};

const PaginationLinks = ({ model }: { model: DefaultFlexModuleResult }) => {
  const { formatText } = useLocalization();
  const previousLabel = formatText("links.pagination.previousLink");
  const nextLabel = formatText("links.pagination.nextLink");

  return (
    <EGDSLayoutFlex direction="row" space="one" wrap="wrap">
      <EGDSLayoutFlexItem>
        {model.pageNumber > 1 ? (
          <EGDSLink inline data-testid="linksPagination-link-prev">
            <TrackedLink
              moduleName={model.name}
              href={model.pagesLinks[model.pageNumber - 2]}
              rfrr={model.strategy}
              linkName={previousLabel}
            >
              {previousLabel}
            </TrackedLink>
          </EGDSLink>
        ) : (
          <EGDSText data-testid="linksPagination-text-prev">{previousLabel}</EGDSText>
        )}
      </EGDSLayoutFlexItem>
      {model.pagesLinks.map((link, index) => {
        const pageNumber = index + 1;
        return (
          <EGDSLayoutFlexItem key={`${model.name}_${index}`}>
            {pageNumber !== model.pageNumber ? (
              <EGDSLink inline data-testid={`linksPagination-link-${pageNumber}`}>
                <TrackedLink moduleName={model.name} href={link} rfrr={model.strategy} linkName={pageNumber}>
                  {pageNumber}
                </TrackedLink>
              </EGDSLink>
            ) : (
              <EGDSText data-testid={`linksPagination-text-${pageNumber}`}>{pageNumber}</EGDSText>
            )}
          </EGDSLayoutFlexItem>
        );
      })}
      <EGDSLayoutFlexItem>
        {model.pagesLinks.length > model.pageNumber ? (
          <EGDSLink inline data-testid="linksPagination-link-next">
            <TrackedLink
              moduleName={model.name}
              href={model.pagesLinks[model.pageNumber]}
              rfrr={model.strategy}
              linkName={nextLabel}
            >
              {nextLabel}
            </TrackedLink>
          </EGDSLink>
        ) : (
          <EGDSText data-testid="linksPagination-text-next">{nextLabel}</EGDSText>
        )}
      </EGDSLayoutFlexItem>
    </EGDSLayoutFlex>
  );
};

const SeeAllContentLink = ({
  model,
  location,
  locationType,
}: {
  model: DefaultFlexModuleResult;
  location: string;
  locationType?: GeoType;
}) => {
  const useNearPreposition =
    locationType &&
    (locationType === LocationType.AIRPORT ||
      locationType === LocationType.POINT_OF_INTEREST ||
      locationType === LocationType.METRO_STATION ||
      locationType === LocationType.TRAIN_STATION);

  const linksMessage = useNearPreposition ? "links.seeAllHotelsNear" : "links.seeAllHotels";

  return (
    <EGDSLink>
      <TrackedLink
        moduleName={model.name}
        href={model.seeAllHotelLinks}
        rfrr={model.strategy}
        linkName={model.seeAllHotelLinks}
      >
        <LocalizedText message={linksMessage} data={[location]} />
      </TrackedLink>
    </EGDSLink>
  );
};

/**
 * Card with links. Handled three different ways. Either it will display
 * auxiliary content in parenthesis, with an additional card to see more info,
 * or none of the former.
 */
export const Links = withStores(
  "flexModuleModelStore",
  "context",
  "flexViewModel"
)(
  observer((props: LinksProps) => {
    const { templateComponent, flexModuleModelStore, insideTabGroupParam, context, flexViewModel } = props;
    const pageName = flexViewModel.trackingContext.pageName;
    const moduleName = "editorial";
    const linkName = "linkstextlink";
    const sectionName = "content";

    if (!templateComponent) {
      return null;
    }
    const {
      metadata: { id },
      config: { fmTitleId },
    } = templateComponent;
    const fmId = getFmId(templateComponent);
    const model = flexModuleModelStore.getModel(id) as DefaultFlexModuleResult | null;

    const [toggle, setToggle] = React.useState(model?.toggle === "collapsed");
    let insideTabGroup = React.useContext(InsideTabGroupContext);
    insideTabGroup = insideTabGroupParam || insideTabGroup;
    if (!model || !model.links || model.links.length === 0) {
      return null;
    }

    const DEFAULT_MOBILE_DISPLAY_COLUMNS = 1;
    const { dropDownArrowSize = "SMALL", withoutTitleSeparator, containerInlinePadding } = model;
    const displayColumns = Number(model.displayColumns) as EGDSLayoutGridColumnRow;
    const location = model.locationName || context.searchContext.location?.name;
    const locationType = model.locationType || context.searchContext.location?.type;
    const keyHelper = new ItemKeyHelper("links");
    const containerPadding: EGDSSpacingProps["padding"] = insideTabGroup
      ? {}
      : { blockstart: "three", inline: containerInlinePadding as EGDSSpacingSpaces["inline"] };
    const tabPanelPadding: EGDSSpacingProps["padding"] =
      toggle && !insideTabGroup ? { inline: "three", block: "three" } : { inline: "two", block: "two" };
    const toggleContainerContext = React.useContext(ToggleAllContainerContext);
    const isToggleDisabled = model.toggle === "none";
    const flipToggle = (): void => {
      if (isToggleDisabled) return;
      setToggle(!toggle);
      toggleContainerContext.toggleCollapseAll(ToggleContainerState.NEITHER);
    };
    const expandoKeyboardPress = (e: React.KeyboardEvent) => {
      if (e.key === "Enter" || e.key === " ") {
        flipToggle();
      }
    };

    const track = useEgClickstream();
    const onViewChange = (inView: boolean) => {
      if (inView) {
        switch (model.strategy) {
          case ALT_ACC_STRATEGY:
            track(egClickstreamAlternateAccommodationsPresentedEvent);
            break;
          default:
            track(egClickstreamLinksPresentedEvent);
            break;
        }
      }
    };

    React.useEffect(() => {
      if (toggleContainerContext.collapseAll === ToggleContainerState.ALL_EXPANDED) {
        setToggle(false);
      } else if (toggleContainerContext.collapseAll === ToggleContainerState.ALL_COLLAPSED) {
        setToggle(true);
      }
    }, [toggleContainerContext.collapseAll]);
    const borderTop = insideTabGroup || withoutTitleSeparator ? undefined : "top";
    const showPagination =
      model.strategy === "travel-guide-city-all-hotels" && model.pagesLinks && model.pagesLinks.length > 1;

    const links = (
      <>
        <EGDSCardContentSection padded={false} border={borderTop}>
          {insideTabGroup && (
            <EGDSSpacing margin={{ inline: "three" }}>
              <EGDSHeading tag="h3" size={5}>
                {model.title}
              </EGDSHeading>
            </EGDSSpacing>
          )}
          <EGDSSpacing padding={tabPanelPadding}>
            <EGDSLayoutGrid
              tag="ul"
              space="one"
              className="no-bullet"
              columns={{ small: DEFAULT_MOBILE_DISPLAY_COLUMNS, large: displayColumns }}
            >
              {model.links.map((el, index) => (
                <EGDSLayoutGridItem colSpan={1} key={keyHelper.next()}>
                  <li>{getEGDSLink(model, el, `${pageName}.${moduleName}.${linkName}${index + 1}.${sectionName}`)}</li>
                </EGDSLayoutGridItem>
              ))}
            </EGDSLayoutGrid>
          </EGDSSpacing>
        </EGDSCardContentSection>
        {showPagination && (
          <EGDSSpacing padding={{ block: "two" }}>
            <EGDSCardContentSection border="top">
              <PaginationLinks model={model} />
            </EGDSCardContentSection>
          </EGDSSpacing>
        )}
        {model.seeAllHotelLinks && location && (
          <EGDSSpacing padding={{ block: "two" }}>
            <EGDSCardContentSection border="top">
              <SeeAllContentLink model={model} location={location} locationType={locationType} />
            </EGDSCardContentSection>
          </EGDSSpacing>
        )}
      </>
    );

    return (
      <LazyLoad context={context}>
        <InView as="div" onChange={onViewChange} triggerOnce>
          <EGDSSpacing padding={containerPadding}>
            <div className="Links" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
              {!insideTabGroup ? (
                <EGDSCard>
                  <div
                    className="CallToAction"
                    tabIndex={0}
                    role="button"
                    aria-expanded={!toggle}
                    onClick={flipToggle}
                    data-testid="expando-toggle"
                    onKeyDown={expandoKeyboardPress}
                  >
                    <EGDSCardContentSection padded>
                      <EGDSLayoutFlex>
                        <EGDSLayoutFlexItem grow={1}>
                          <EGDSHeading size={5} tag="h3">
                            {model.title}
                          </EGDSHeading>
                        </EGDSLayoutFlexItem>
                        <EGDSLayoutFlexItem alignSelf="center" grow={0}>
                          {!isToggleDisabled && (
                            <EGDSIcon
                              name={!toggle ? "expand_less" : "expand_more"}
                              size={EGDSIconSize[dropDownArrowSize as keyof typeof EGDSIconSize]}
                            />
                          )}
                        </EGDSLayoutFlexItem>
                      </EGDSLayoutFlex>
                    </EGDSCardContentSection>
                  </div>
                  <EGDSCardContentSection padded={false} className={`${!toggle ? "" : "is-hidden"}`}>
                    <EGDSSpacing padding={{ blockend: "one", inline: "one" }}>
                      <div>{links}</div>
                    </EGDSSpacing>
                  </EGDSCardContentSection>
                </EGDSCard>
              ) : (
                <EGDSCard>{links}</EGDSCard>
              )}
            </div>
          </EGDSSpacing>
        </InView>
      </LazyLoad>
    );
  })
);

export default Links;
